<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title">CBS List</v-toolbar-title>
      <v-spacer />
      <!--      <v-btn outlined @click="showGenerateModal = true" class="mr-3">-->
      <!--        <v-icon class="mr-1">mdi-family-tree</v-icon>-->
      <!--        <div class="reload-text">Generate Report</div>-->
      <!--      </v-btn>-->
      <v-btn outlined @click="() => this.fetchRegistrations()">
        <v-icon class="mr-1">mdi-reload</v-icon>
        <div class="reload-text">Reload</div>
      </v-btn>
    </v-toolbar>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          disable-sort
          disable-filtering
          :items="registrations"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :server-items-length="totalItems"
          item-key="id"
          class="elevation-2"
          id="match-table"
          :footer-props="footerProps"
          style="cursor: pointer"
          @click:row="(item) => openDetailsOf(item)"
        >
          <template v-slot:top>
            <div class="pa-2 lighten-2 d-lg-flex align-center">
              <v-btn color="primary" class="ma-1 pa-5" @click="showModal = !showModal">
                <v-icon>mdi-filter</v-icon> Filter
              </v-btn>
              <v-combobox
                v-model="filterChips"
                solo
                flat
                chips
                multiple
                label="Click filter button to apply filter"
                class="chips-combo search-field ma-1"
                readonly
                hide-details
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-chip v-bind="attrs">
                    <strong>{{ item }}</strong> &nbsp;
                    <v-icon small @click="removeFilter(item)"> $delete</v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`footer.prepend`]>
            <div class="pa-2 lighten-2 d-lg-flex align-center">
              <v-btn color="success" class="ma-1" @click="downloadAsCsv">Download as CSV</v-btn>
            </div>
          </template>
          <!--          <template v-slot:[`item.mobileNo`]="{ item }">-->
          <!--            <v-btn small outlined :to="{ name: 'user.view', params: { id: item.userId } }">-->
          <!--              {{ item.mobileNo }}-->
          <!--            </v-btn>-->
          <!--          </template>-->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.statusMessage`]="{ item }">
            {{ (item.statusMessage || "").replaceAll(/\d+/g, "") }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-btn
              small
              rounded
              v-if="
                item.status === 'IN_REVIEW' && ['ADMIN', 'MAKER', 'ROOT'].includes(getUserRole())
              "
              @click.stop="openReviewModal(item.id, `${item.firstName} ${item.lastName}`, item.nid)"
            >
              In Review <v-icon small>mdi-eye</v-icon>
            </v-btn>
            <v-chip small v-else-if="item.status">{{ item.status }}</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="ma-1" outlined small @click="openDetailsOf(item)"> More</v-btn>
            <v-btn
              class="ma-1"
              v-if="item.status === 'DRAFT' && ['ADMIN', 'ROOT'].includes(getUserRole())"
              outlined
              small
              @click="registerClicked(item)"
            >
              Register
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" max-width="900px" scrollable>
      <filters @filterSelected="filterSelected" :initial-values="filterValues" />
    </v-dialog>
    <v-dialog v-model="showGenerateModal" max-width="600px" scrollable>
      <generate-report @close="showGenerateModal = false" />
    </v-dialog>
    <v-dialog
      v-model="showReviewModal"
      v-if="reviewId && reviewName && reviewName"
      max-width="900px"
      scrollable
    >
      <ReviewModal @close="closeReviewModal" :id="reviewId" :name="reviewName" :nid="reviewNid" />
    </v-dialog>
  </v-container>
</template>

<script>
import {
  ONBOARDING_CBS_GENERATE_REPORT,
  ONBOARDING_CBS_REGISTRATIONS,
  ONBOARDING_CBS_REGISTER,
} from "@/constants/api";
import Filters from "@/views/pages/dashboard/cbs/Filters";
import GenerateReport from "@/views/pages/dashboard/cbs/GenerateReport";
import _ from "lodash";
import moment from "moment";
import ReviewModal from "@/views/pages/dashboard/cbs/ReviewModal";

export default {
  name: "CBSIndex",
  metaInfo: { title: "Onboarding (CBS)" },
  components: { Filters, GenerateReport, ReviewModal },
  data: () => ({
    loading: false,
    showModal: false,
    showGenerateModal: false,
    headers: [
      { text: "Type", value: "type" },
      { text: "NID", value: "nid", width: 150 },
      { text: "Date of Birth", value: "dob", width: 120 },
      { text: "First Name", value: "firstName", width: 150 },
      { text: "Last Name", value: "lastName", width: 150 },
      { text: "Created At", value: "createdAt", width: 200 },
      { text: "Status", value: "status" },
      { text: "Status Message", value: "statusMessage" },
      { text: "Review", value: "review" },
      { text: "", value: "actions" },
    ],
    registrations: [],
    totalItems: null,
    optionsLength: 6,
    footerProps: { "items-per-page-options": [15, 30, 60, 100] },
    filterValues: {},
    filterChips: [],
    showReviewModal: false,
    reviewId: null,
    reviewName: null,
    reviewNid: null,
    rawFilterData: [],
  }),
  computed: {
    page: {
      get() {
        return Number(this.$route.query.page) || 1;
      },
      set(val, old) {
        if (val !== old) this.pushCurrentRoute({ page: val });
      },
    },
    itemsPerPage: {
      get() {
        return Number(this.$route.query.limit) || 15;
      },
      set(val, old) {
        if (val !== old) this.pushCurrentRoute({ page: 1, limit: val });
      },
    },
  },
  watch: {
    $route({ name }) {
      if (name === "cbs.index") {
        this.fetchRegistrations();
      }
    },
  },
  mounted() {
    this.fetchRegistrations();
  },
  methods: {
    openDetailsOf(item) {
      this.$router.push({ name: "cbs.view", params: { id: item.id } });
    },
    async registerClicked(item) {
      console.log("registerClicked", item);
      try {
        this.loading = true;
        const response = await this.$axios.post(`${ONBOARDING_CBS_REGISTER}?id=${item.id}`);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        console.log(response);
        this.$iziToast.success({
          title: "Registration successful",
        });
        await this.fetchRegistrations();
      } catch (err) {
        console.error(err);
        this.$iziToast.showError(err);
      } finally {
        await this.$nextTick();
        this.loading = false;
      }
    },
    async pushCurrentRoute({ page, limit }) {
      await this.$nextTick();
      const q = {};
      q.page = page === undefined ? this.page : page;
      q.limit = limit === undefined ? this.itemsPerPage : limit;
      Object.keys(q).forEach((el) => {
        if (!q[el]) delete q[el];
      });

      this.$router.push({ name: this.$route.name, query: q }).catch(() => {});
    },
    async fetchRegistrations() {
      this.loading = true;
      try {
        this.registrations = [];
        const params = {
          page: this.page - 1,
          limit: this.itemsPerPage,
        };
        const response = await this.$axios.post(ONBOARDING_CBS_REGISTRATIONS, this.filterValues, {
          params,
        });
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.totalItems = response.data.data.totalItems;
        this.registrations = response.data.data.items.map((item) => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    async downloadAsCsv() {
      // alert("GENERATE REPORT API NOT FOUND");
      this.loading = true;
      try {
        const response = await this.$axios.post(ONBOARDING_CBS_GENERATE_REPORT, this.filterValues);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.downloadCsvFile(response.data.data, "onboarding_export_" + moment().toISOString());
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    downloadCsvFile(data, name) {
      let aTag = window.document.createElement("a");
      aTag.href = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
      aTag.download = `${name}.csv`;
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
    },
    filterSelected(data) {
      this.showModal = false;
      this.rawFilterData = data;
      this.updateChips();
      Object.keys(data).map((key) => {
        if (["startTime", "endTime", "dob"].includes(key)) {
          data[key] = moment(data[key], "YYYY-MM-DD").valueOf();
        }
      });
      this.filterValues = data;
      this.fetchRegistrations();
    },
    prettify(name) {
      return _.startCase(name);
    },
    openReviewModal(id, name, nid) {
      this.reviewId = id;
      this.reviewName = name;
      this.reviewNid = nid;
      this.showReviewModal = true;
    },
    closeReviewModal() {
      this.showReviewModal = false;
      setTimeout(() => {
        this.fetchRegistrations();
        this.reviewId = null;
        this.reviewName = null;
        this.reviewNid = null;
      }, 2000);
    },
    updateChips() {
      let data = this.rawFilterData;
      this.filterChips = [];
      Object.keys(data).forEach((el) => {
        if (data[el]) {
          this.filterChips.push(`${this.prettify(el)}: ${data[el]}`);
        }
      });
    },
    async removeFilter(item) {
      try {
        let toBeRemovedKey = item.split(":")[0];
        if (toBeRemovedKey) {
          this.$delete(this.filterValues, _.camelCase(toBeRemovedKey));
          await this.$nextTick();
          this.updateChips();
          await this.fetchRegistrations();
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.chips-combo {
  .v-input__append-inner {
    display: none !important;
  }
}
</style>
