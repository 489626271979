<template>
  <v-card>
    <v-card-title class="text-h5 font-weight-regular"> Filter CBS List </v-card-title>
    <v-card-text>
      <v-row justify="center" class="ma-5">
        <v-col md="6">
          <v-select
            v-model="formValues['type']"
            :items="typeOptions"
            label="Type"
            class="pa-0 mb-1"
            outlined
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
          />
        </v-col>
        <v-col md="6">
          <v-select
            class="pa-0 mb-1"
            outlined
            hide-details
            v-model="formValues['status']"
            :items="statusOptions"
            label="Status"
            :menu-props="{ bottom: true, offsetY: true }"
          />
        </v-col>
        <v-col md="6">
          <v-select
            class="pa-0 mb-1"
            outlined
            hide-details
            v-model="formValues['review']"
            :items="reviewOptions"
            label="Review"
            :menu-props="{ bottom: true, offsetY: true }"
          />
        </v-col>
        <v-col md="6">
          <v-select
            class="pa-0 mb-1"
            outlined
            hide-details
            v-model="formValues['amlMatched']"
            :items="amlOptions"
            label="AML Matched"
            :menu-props="{ bottom: true, offsetY: true }"
          />
        </v-col>
        <v-col md="6">
          <v-datetime-picker
            :textFieldProps="{ outlined: true, hideDetails: true }"
            label="Select start date time"
            v-model="startTimeStr"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>

            <template slot="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col md="6">
          <v-datetime-picker
            :textFieldProps="{ outlined: true, hideDetails: true }"
            label="Select end date time"
            v-model="endTimeStr"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>

            <template slot="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col md="6">
          <v-menu
            ref="menu3"
            v-model="dobMenu"
            :close-on-content-click="false"
            :return-value.sync="dobTimeStr"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dobTimeStr"
                label="Date of Birth"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="dobTimeStr" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dobMenu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu3.save(dobTimeStr)"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="6" v-for="item in Object.keys(formItems)" :key="item">
          <v-text-field
            class="pa-0 mb-1"
            outlined
            hide-details
            :label="prettify(item)"
            v-model="formValues[item]"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="filterSelected"> Filter </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";

export default {
  name: "Filters",
  props: {
    initialValues: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mounted() {
    this.formValues = this.initialValues || {};
  },
  data() {
    return {
      startTimeMenu: false,
      endTimeMenu: false,
      dobMenu: false,
      startTimeStr: null,
      endTimeStr: null,
      dobTimeStr: null,
      formValues: {},
      formItems: {
        accountCode: "string",
        amlReferenceNo: "string",
        // dob: "2022-04-12",
        mobileNo: "string",
        firstName: "string",
        lastName: "string",
        middleName: "string",
        nid: "string",
        // startTime: 0,
        // stopTime: 0,
        surName: "string",
      },
      typeOptions: ["CBS_SELF"],
      statusOptions: [
        "DRAFT",
        "IN_QUEUE",
        "PROCESSING",
        "IN_REVIEW",
        "FAILURE",
        "SUCCESS",
        "REJECT",
        "REVIEW_PENDING",
      ],
      amlOptions: ["true", "false"],
      reviewOptions: ["APPROVED", "REJECTED"],
    };
  },
  methods: {
    filterSelected() {
      if (this.formValues["status"]) {
        this.formValues["status"] = _.upperCase(this.formValues["status"]).replaceAll(" ", "_");
      }
      if (this.startTimeStr) {
        let d = new Date(this.startTimeStr) || new Date();
        this.formValues["startTime"] = d.toISOString();
      }
      if (this.endTimeStr) {
        let d = new Date(this.endTimeStr) || new Date();
        this.formValues["endTime"] = d.toISOString();
      }
      if (this.dobTimeStr) {
        this.formValues["dob"] = this.dobTimeStr;
      }
      this.$emit("filterSelected", this.formValues);
    },
    prettify(name) {
      return _.startCase(name);
    },
  },
};
</script>

<style scoped></style>
