<template>
  <v-card :loading="loading">
    <v-card-title class="text-h5 font-weight-regular">Review</v-card-title>
    <v-card-text>
      <v-row justify="center" v-if="!loading">
        <v-col cols="12" md="12">
          <h3 class="text-center mb-2">SDN Entry List</h3>
          <AmlList
            v-if="registration && registration.sdnEntryList && registration.sdnEntryList.length > 0"
            :rows="registration.sdnEntryList"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" class="text--white" @click="approve"> Approve </v-btn>
      <v-btn color="success" @click="reject"> Reject</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ONBOARDING_CBS_GET_BY_UID, ONBOARDING_CBS_SELF_REVIEW } from "@/constants/api";
import AmlList from "@/views/pages/dashboard/cbs/AmlList";

export default {
  name: "ReviewModal",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    nid: {
      type: String,
      required: true,
    },
  },
  components: { AmlList },
  data() {
    return {
      loading: true,
      registration: null,
    };
  },
  async mounted() {
    await this.fetchRegistration();
  },

  methods: {
    async fetchRegistration() {
      const response = await this.$axios.get(`${ONBOARDING_CBS_GET_BY_UID}/${this.id}`);
      if (response.data.code !== 0) {
        this.registration = {};
        throw new Error(response.data.message);
      }
      this.registration = response.data.data;
      this.loading = false;
    },
    async submitReview(review) {
      this.loading = true;
      let response = null;
      try {
        response = await this.$axios.post(
          `${ONBOARDING_CBS_SELF_REVIEW}/?id=${this.id}&review=${review}&name=${this.name}&nid=${this.nid}`
        );
      } catch (e) {
        response = e.response;
      }
      if (response.data.code !== 0) {
        this.$iziToast.warning({ title: response.data.message });
        this.loading = false;
        this.$emit("close");
      } else {
        this.$iziToast.success({ title: "Success", message: response.data.message });
        this.loading = false;
        this.$emit("close");
      }
    },
    approve() {
      this.submitReview("APPROVED");
    },
    reject() {
      this.submitReview("REJECTED");
    },
  },
};
</script>

<style scoped></style>
