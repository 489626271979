<template>
  <v-card>
    <v-card-title class="text-h5 font-weight-regular"> Generate Report</v-card-title>
    <v-card-text>
      <v-row justify="center" v-if="!loading">
        <v-col cols="12" md="6">
          <v-datetime-picker label="Select Start Time" v-model="startTime">
            <div slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </div>
            <div slot="timeIcon">
              <v-icon>mdi-calendar-clock</v-icon>
            </div>
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" md="6">
          <v-datetime-picker label="Select End Time" v-model="endTime">
            <div slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </div>
            <div slot="timeIcon">
              <v-icon>mdi-calendar-clock</v-icon>
            </div>
          </v-datetime-picker>
        </v-col>
      </v-row>
      <v-card-text class="text-center grey--text py-15" v-else>
        <v-progress-circular indeterminate size="44" />
        <div class="caption my-2">Creating report ...</div>
      </v-card-text>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="warning" @click="$emit('close')"> Close</v-btn>
      <v-btn color="primary" @click="downloadReport" :disabled="loading"> Download Report</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import {
  ONBOARDING_USER_IDS,
  REPORT_CBS_GENERATE_BATCH,
  REPORT_CBS_GET_REPORT,
} from "@/constants/api";

export default {
  name: "GenerateReport",
  data() {
    return {
      loading: false,
      startTime: null,
      endTime: null,
      userIds: [],
      reportId: null,
    };
  },
  methods: {
    validateData() {
      if (!this.startTime) {
        this.$iziToast.error({ title: "Start time is required" });
        return false;
      }
      if (!this.endTime) {
        this.$iziToast.error({ title: "End time is required" });
        return false;
      }
      if (moment(this.endTime).isBefore(moment(this.startTime))) {
        this.$iziToast.error({
          title: "Invalid time",
          message: "End time can not be before start time",
        });
        return false;
      }
      return true;
    },
    async downloadReport() {
      if (this.validateData()) {
        try {
          this.loading = true;
          await this.fetchUsers();
          if (!this.userIds) {
            this.loading = false;
            return;
          }
          if (Array.isArray(this.userIds) && this.userIds.length === 0) {
            this.$iziToast.warning({ title: "No data found on this time range" });
            this.loading = false;
            return;
          }
          const response = await this.$axios.post(REPORT_CBS_GENERATE_BATCH, this.userIds);
          if (response.data.code !== 0) {
            throw new Error(response.data.message);
          }
          this.reportId = response.data.data.reportId;
          await this.fetchReport();
        } catch (e) {
          this.$iziToast.showError(e);
          this.loading = false;
        }
      }
    },
    async fetchUsers() {
      try {
        this.userIds = null;
        const response = await this.$axios.get(
          ONBOARDING_USER_IDS +
            `?startTime=${moment(this.startTime).format("x")}&stopTime=${moment(
              this.endTime
            ).format("x")}`
        );
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.userIds = response.data.data;
      } catch (e) {
        this.$iziToast.showError(e, "Can not fetch user ids");
      }
    },
    async fetchReport() {
      if (!this.reportId) {
        return;
      }
      this.loading = true;
      try {
        const response = await this.$axios.get(
          REPORT_CBS_GET_REPORT + "?reportId=" + this.reportId
        );
        // console.log(response);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        console.log("State:", response.data.data.state);
        if (response.data.data.state === "COMPLETED") {
          // console.log(response.data.data);
          if (response.data.data.content) {
            await this.downloadPdfFile(
              response.data.data.content,
              "report_" + moment().toISOString()
            );
          } else {
            throw new Error("PDF can not be generated");
          }
          this.loading = false;
        } else {
          setTimeout(this.fetchReport, 3000);
        }
      } catch (e) {
        this.$iziToast.showError(e, "Can not generate report");
      }
    },
    downloadPdfFile(data, name) {
      let aTag = window.document.createElement("a");
      aTag.href = "data:application/pdf;base64," + data;
      aTag.download = `${name}.pdf`;
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
    },
  },
};
</script>

<style scoped></style>
